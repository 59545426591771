import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Side from './Side';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />; //eslint-disable-line
}

function ListFlights({
  handleClose,
  viewportHeight,
  viewportWidth,
  dataUrl,
}) {
  const { username } = useParams();

  const [flights, setFlights] = useState([]);

  useEffect(() => {
    const url = `${dataUrl()}listFlights?username=${username}`;
    axios.get(url)
      .then((res) => {
        setFlights(res.data.sort((a, b) => (Number(a.flight_id) > Number(b.flight_id) ? -1 : 1)));
      })
      .catch(() => {});
  }, []);

  return (
    <Side
      title="List Flights"
      viewportWidth={viewportWidth}
      viewportHeight={viewportHeight}
      handleClose={handleClose}
    >
      <Box p={1}>
        {flights.length === 0 && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        <List component="nav">
          {flights.map((flight, index) => (
            /* eslint-disable-next-line */
            <ListItemLink key={index} href={`/${username}/${flight.flight_id}`}>
              <ListItemText>
                {`${moment(flight.start * 1000).format('D.M.YYYY')
                }, flight ${flight.flight_id}`}
              </ListItemText>
            </ListItemLink>
          ))}
        </List>
      </Box>
    </Side>
  );
}

export default ListFlights;
