import { useEffect } from 'react';
import axios from 'axios';
import dataUrl from './dataUrl';

function BalloonWithText({ map, username }) {

    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('load', async function () {
            map.addSource(`${username}-source`, {
                type: 'geojson',
                data: {
                    "type": "Feature",
                    "properties": {
                        'heading': 45,
                        'username': username,
                        'altitude': 1640,
                        'speed': 43,
                    },
                    "geometry": {
                        "coordinates": [
                            7.36502986326272,
                            46.71390522094933
                        ],
                        "type": "Point"
                    }
                },
            });

            const imagePathMap = {
                'berta': '/hb-bnj.png',
                'horni': '/hb-bal.png',
                'waelchli': '/hb-quu.png',
                'wipfli': '/hb-bnj.png',
            };
            const response = await map.loadImage(imagePathMap[username]);
            map.addImage(username, response.data);

            map.addLayer({
                id: `${username}-pin-text`,
                type: 'symbol',
                source: `${username}-source`,
                layout: {
                    'symbol-placement': 'point',
                    'icon-image': username,
                    'text-allow-overlap': true,
                    'icon-allow-overlap': true,
                    'text-field': [
                        'format',
                        ['get', 'speed'],
                        {},
                        ' km/h, ',
                        ['get', 'heading'],
                        {},
                        '°\n',
                        {},
                        ['get', 'altitude'],
                        {},
                        'm',
                        {}
                    ],
                    'text-font': [
                        'Fira Sans Regular',
                    ],
                    'text-size': 14,
                    'icon-size': 0.75,
                    'text-justify': 'center',
                    'text-anchor': 'top',
                    'text-offset': [
                        0.0,
                        2.0,
                    ],
                    'icon-offset': [
                        0,
                        0.0
                    ]
                },
                paint: {
                    'text-color': 'black',
                    'text-halo-blur': 0.5,
                    'text-halo-width': 1,
                    'text-halo-color': 'rgba(255, 255, 255, 1)',
                    'text-opacity': 1.0,
                    'icon-opacity': 1.0,
                },
            });

            setInterval(() => {
                const url = `${dataUrl()}now?username=${username}`;
                axios.get(url)
                    .then((res) => {
                        const geojson = {
                            "type": "Feature",
                            "properties": {
                                'heading': res.data.heading.toFixed(0),
                                'username': username,
                                'altitude': res.data.altitude.toFixed(0),
                                'speed': (3.6 * res.data.speed).toFixed(1),
                            },
                            "geometry": {
                                "coordinates": [
                                    res.data.longitude,
                                    res.data.latitude
                                ],
                                "type": "Point"
                            }
                        };
                        map.getSource(`${username}-source`).setData(geojson);
                    })
                    .catch(() => {
                    });
            }, 1000);
        });

    }, [map]);

    return null;
}

export default BalloonWithText;
